.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--layout-primary-color);
  padding: 0.5rem;
  border-radius: var(--border-radius-button);
  box-sizing: border-box;
  cursor: pointer;
  font-family: var(--main-font);
  height: 3rem;
  width: 3rem;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  text-align: center;
  user-select: none;
}

.button:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
}

.primary {
  background-color: var(--layout-primary-color);
  color: white;
}

.primary:hover {
  background-color: var(--layout-secondary-color);
  border-color: var(--layout-secondary-color);
}

.secondary {
  background-color: transparent;
  border-color: var(--skp-color-neutral-500);
  color: var(--skp-color-neutral-900);
}

.secondary svg {
  color: var(--skp-color-neutral-900);
}

.secondary:hover {
  border-color: var(--skp-color-neutral-500);
  color: var(--layout-secondary-color);
}

.icon {
  height: 100%;
  width: 100%;
}
