.listContainer {
  background-color: var(--neutral-50);
  padding: 1rem;
}

.listHeader {
  align-items: center;
  display: flex;
}

.listHeaderTitle {
  flex: 1 0 0;
}

.listHeaderSubtitle {
  align-items: center;
  display: flex;
}

.listHeaderSubtitle [class^='style_labelContainer'] {
  margin-right: 0.5rem;
}

.list {
  margin-top: 1rem;
  max-height: 40vh;
  overflow: auto;
}

.listRow {
  background-color: var(--skp-color-white);
  border: 1px solid var(--skp-color-neutral-100);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.listRow:not(:last-child) {
  margin-bottom: 1rem;
}

.rowImage {
  height: 2rem;
  max-width: 2rem;
  width: 2rem;
}

.limitText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.rowTitle {
  flex: 1.25;
  font-weight: 700;
}

.rowDescription {
  flex: 1.75;
  font-size: 0.75rem;
}

.rowTags {
  flex: 0.7;
}

.rowTagsPill {
  background-color: var(--skp-color-alternative-purple-500);
  border-radius: 50px;
  color: var(--white);
  font-size: 0.65rem;
  padding: 0.25rem 0.5rem;
}

.rowPrivacy,
.rowSubtitle {
  align-items: center;
  display: flex;
  flex: 1.2;
}

.privacyStatusIcon {
  margin-right: 0.75rem;
}

.subtitleStatusIcon {
  margin-right: 0.6rem;
}

.privacyStatusText,
.subtitleStatusText {
  color: var(---skp-color-neutral-500);
  font-size: 0.85rem;
}

.rowEdit {
  flex: 0.5;
  text-align: right;
}

.buttonEdit {
  height: 2rem;
  width: 2rem;
  padding: 0.5rem;
  font-size: 0.75rem;
}

@media screen and (max-height: 900px) {
  .list {
    max-height: 35vh;
  }
}

@media screen and (max-height: 720px) {
  .list {
    max-height: 25vh;
  }
}
