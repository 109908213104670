.grid {
  flex: 1 1 auto;
  display: grid;
  grid-template-areas: 'player cards';
  grid-template-columns: 2fr 3fr;
  gap: 1rem;
  overflow: auto;
}

@media (max-width: 900px) {
  .grid {
    grid-template-areas:
      'player'
      'cards';
    grid-template-columns: 1fr;
  }
}
