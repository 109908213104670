.wrapper {
  display: flex;
  position: relative;
  justify-content: center;
}

.center {
  justify-content: center;
}

.action {
  position: relative;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 2rem 0 1rem 0;
}

.switchMode {
  display: flex;
  align-items: center;
}

.switchButton {
  margin: 0 20px;
}

.switchText {
  text-align: center;
  width: 85px;
  font-weight: 600;
}

.addTextButton {
  font-weight: 600;
  height: 35px;
  left: 0;
  padding: 0.4rem 1.2rem;
  border-radius: var(--border-radius-button);
  border: 2px solid var(--layout-primary-color);
  background-color: transparent;
  cursor: pointer;
  color: var(--layout-primary-color);
  margin: 0;
  overflow: hidden;
}

.addTextButton:hover {
  color: white;
  background-color: var(--layout-primary-color);
}

.editor {
  position: absolute;
  width: 100%;
  height: 100%;
}

.videoBox {
  background: url('./assets/videoBoxBackground.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.8;
}

.videoBox,
.textBox {
  max-width: 100%;
  max-height: 50vh;
  position: absolute;
  box-sizing: border-box;
  cursor: move;
  z-index: 2;
}

.videoBox:hover,
.textBox:hover {
  outline: dashed 1px red;
  outline-offset: -1px;
}

.textOptions {
  display: none;
  position: absolute;
  top: -21px;
  left: -1px;
}

.sortButtonContainer {
  display: flex;
}

.sortButton {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: white;
  position: relative;
  width: 15px;
  height: 10px;
  margin: 0;
  padding: 0;
  border: none;
  background: var(--layout-primary-color);
  overflow: hidden;
}

.textOptions .buttonAction {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  border: none;
  background: var(--layout-primary-color);
  overflow: hidden;
}

.buttonActive {
  color: rgb(255, 255, 0);
}

.buttonInactive {
  color: white;
}

.textBox:hover .textOptions {
  display: flex;
}

.textColorPicker {
  position: relative;
  border-color: var(--layout-primary-color);
  height: 16px;
  padding: 0;
  width: 55px;
}

.inputTexts {
  pointer-events: none;
  opacity: 0;
}

.textHighlightOpacity {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  border: 2px solid var(--layout-primary-color);
  overflow: hidden;
}

.select {
  border-color: #7272ff;
  border-width: 2px;
}

.uploadFont {
  cursor: pointer;
  color: white;
  width: 20px;
  background: #7272ff;
  overflow: hidden;
  height: 20px;
  margin: 0;
  padding: 0;
  border: none;
}

.resizeTop,
.resizeRight,
.resizeBottom,
.resizeLeft,
.resizeTopLeft,
.resizeTopRight,
.resizeBottomLeft,
.resizeBottomRight {
  width: 10px;
  height: 10px;
  background-color: #7272ff;
  position: absolute;
  opacity: 0.7;
  display: none;
}

.textBox:hover .resizeTop,
.textBox:hover .resizeRight,
.textBox:hover .resizeBottom,
.textBox:hover .resizeLeft,
.textBox:hover .resizeTopLeft,
.textBox:hover .resizeTopRight,
.textBox:hover .resizeBottomLeft,
.textBox:hover .resizeBottomRight,
.videoBox:hover .resizeTop,
.videoBox:hover .resizeRight,
.videoBox:hover .resizeBottom,
.videoBox:hover .resizeLeft,
.videoBox:hover .resizeTopLeft,
.videoBox:hover .resizeTopRight,
.videoBox:hover .resizeBottomLeft,
.videoBox:hover .resizeBottomRight {
  display: block;
}

.resizeTop {
  top: -5px;
  left: calc(50% - 5px);
  cursor: ns-resize;
}

.resizeRight {
  right: -5px;
  top: calc(50% - 5px);
  cursor: ew-resize;
}

.resizeBottom {
  bottom: -5px;
  left: calc(50% - 5px);
  cursor: ns-resize;
}

.resizeLeft {
  top: calc(50% - 5px);
  left: -5px;
  cursor: ew-resize;
}

.resizeTopLeft {
  top: -5px;
  left: -5px;
  cursor: nwse-resize;
}

.resizeTopRight {
  right: -5px;
  top: -5px;
  cursor: nesw-resize;
}

.resizeBottomLeft {
  bottom: -5px;
  left: -5px;
  cursor: nesw-resize;
}

.resizeBottomRight {
  bottom: -5px;
  right: -5px;
  cursor: nwse-resize;
}

.disabled {
  cursor: not-allowed;
}

.canvas {
  max-width: 100%;
  max-height: 50vh;
  outline: solid 1px black;
  z-index: 1;
}

.canvasOverlay {
  max-width: 100%;
  max-height: 50vh;
  pointer-events: none;
  position: absolute;
  background: repeating-conic-gradient(#d3d3d3 0% 25%, transparent 0% 50%) 50% / 20px 20px;
}

.backgroundAlpha {
  background: repeating-conic-gradient(#d3d3d3 0% 25%, transparent 0% 50%) 50% / 20px 20px;
}

.tagsText {
  font-weight: 400;
  font-size: 14px;
  padding-top: 0.5rem;
  margin-left: 0.2rem;
}

.tagTitle {
  padding-bottom: 0.5rem;
}

.tags {
  display: flex;
}

.tagElement {
  margin-left: 0;
}
