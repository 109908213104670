.mini {
  cursor: pointer;
  color: var(--main-selection-color);
  transition: color 0.2s ease-in-out;
}

.root {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: var(--border-radius-button);
  background-color: var(--input-background-color);
  cursor: pointer;
  position: relative;
}

.text,
.copied {
  color: var(--layout-box-title-color);
  text-decoration: none;
  word-break: break-all;
}

.text:hover,
.icon:hover {
  color: var(--layout-primary-color) !important;
}

.text:visited {
  color: var(--layout-box-title-color);
}

.icon {
  margin-left: 0.5rem;
  color: var(--layout-box-title-color);
}

.rootMini {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.infoCopy {
  display: flex;
  padding: 0.4rem;
  border-radius: var(--border-radius-button);
  background-color: var(--input-background-color);
  font-size: 0.8rem;
  transition: opacity 0.2s ease-in-out;
  user-select: none;
}
