.table {
    width: 100%;
}

.table .columnFull {
    flex: 1;
}

.table .columnHalf {
    flex: 0.5;
}

.table .columnTier {
    flex: 0.33;
}

.table .columnSmall {
    flex: 0.15;
}

.table .textCenter {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.table .textCenter > div, .table .textCenter > skp-flex > div {
    align-items: center;
}

.table .textCenter > skp-tooltip {
    width: fit-content;
    display: block;
    margin: auto;
}

.table .textCenter > skp-icon {
    width: fit-content;
    display: block;
}
