.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.downloadIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.filename {
  font-size: 1rem;
  font-weight: 600;
  font-style: italic;
  color: var(--video-text-color-secondary);
}

.video {
  width: 100%;
  border-radius: 0.5rem;
  outline: none;
  max-height: 500px;
  background-color: black;
}
