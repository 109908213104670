.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.downloadIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.filename {
  font-size: 1rem;
  font-weight: 600;
  font-style: italic;
  color: var(--video-text-color-secondary);
}

.slide {
  margin: 0 20px;
}

.image,
.slide video,
.slide audio {
  outline: none;
  border-radius: 0.5rem;
  overflow: hidden;
}

.slide video {
  background-color: black;
}

.slide audio {
  background-image: url('./assets/audio.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.thumb {
  position: relative;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transform-origin: center center;
  width: 0.75rem;
  height: 0.75rem;
  fill: var(--picto-color);
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
}

.player {
  text-align: center;
}

:global(.carousel) :global(.control-prev)::before {
  border-right: 8px solid var(--layout-primary-color) !important;
}

:global(.carousel) :global(.control-next)::before {
  border-left: 8px solid var(--layout-primary-color) !important;
}

:global(.carousel) :global(.thumbs) {
  padding-left: 0 !important;
  margin: 0;
}

:global(.carousel) :global(.thumb) {
  padding: 0;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-right: 2px;
}

:global(.carousel) :global(.slide) {
  background-color: var(--layout-box-background-color) !important;
}

:global(.carousel) :global(.thumb.selected),
:global(.thumb:hover) {
  border: 3px solid var(--layout-primary-color) !important;
}

:global(.carousel) :global(.control-arrow) {
  opacity: 1 !important;
}

:global(.carousel) :global(.control-next) {
  right: -10px !important;
}

:global(.carousel) :global(.control-prev) {
  left: -10px !important;
}

:global(.carousel) :global(.thumbs-wrapper) {
  margin: 0 20px;
}
