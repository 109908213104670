.flex {
  display: flex;
  padding-top: 1rem;
}

.flex > *:not(:first-child) {
  margin-left: 1rem;
}

.right {
  justify-content: flex-end;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}
