.container {
  width: 100%;
}

.subcontent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.loginError {
  color: var(--color-error);
}

.loginContainer {
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
}

.loginContainer p {
  flex: 1 0 0;
  margin: 0;
}
