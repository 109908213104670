.link {
  color: var(--layout-primary-color) !important;
  cursor: pointer;
  display: flex;
}

.link:hover {
  opacity: 0.8;
}

.downloadIcon {
  margin-left: 0.5rem;
}

.filesList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  outline: none;
  height: 100%;
  width: 100%;
}

.iconPreview {
  height: 5rem;
  width: 5rem;
  cursor: pointer;
  font-size: 2rem;
  transition: color 0.2s ease-in-out;
}

.mediaTitleContainer {
  display: flex;
  justify-content: center;
}

.links {
  display: flex;
}

.verticalAlign {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.mediaTitle {
  text-overflow: ellipsis;
  color: var(--video-text-color-secondary);
  font-family: var(--main-font);
  font-weight: 600;
  font-size: 0.75rem;
  font-style: italic;
  background-color: transparent;
  margin: 0;
  width: 100%;
  text-align: justify;
}

.mediaTitleReadOnly {
  margin-right: 0.5rem;
}

.icon {
  width: 2rem;
  height: 2rem;
  padding-bottom: 0.7rem;
}
.icon svg {
  width: 24px !important;
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto;
}

.preview svg {
  font-size: 2rem;
}

.message {
  font-weight: 600;
  font-size: 1.05rem;
  margin: 0;
}

.or {
  line-height: 2rem;
  font-size: 0.8rem;
  font-style: italic;
}

.p {
  color: var(--video-text-color-secondary);
}

.percent {
  display: block;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 100%;
}

.downloadText {
  font-size: 0.7rem;
}

.image {
  height: 100%;
  width: 100%;
  min-height: 8rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.spinnerContainer {
  width: 100%;
  position: relative;
  min-height: 40px;
  margin-bottom: 20px;
}

.spinner {
  margin: auto;
  transform: scale(var(--ggs, 1));
  color: var(--layout-primary-color);
}
.spinner,
.spinner::after,
.spinner::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 1rem;
  height: 1rem;
}
.spinner::after,
.spinner::before {
  content: '';
  position: absolute;
  border-radius: 100px;
}
.spinner::before {
  animation: spinner 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  border: 3px solid transparent;
  border-top-color: currentColor;
}
.spinner::after {
  border: 3px solid;
  opacity: 0.2;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.error {
  border: 1px solid var(--color-error);
}
