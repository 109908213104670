.title {
  color: var(--skp-color-neutral-900);
  margin-bottom: 0.2rem;
}

.subtitle {
  color: var(--color-grey);
  user-select: none;
  margin: 0;
  font-weight: 600;
}

.group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  margin: 0.4rem 0;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

@media (min-width: 950px) {
  .actions.vertical {
    flex-direction: column;
  }
}
