.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list > .row {
  display: flex;
}

.list > .row > .icon {
  flex: 0 0 2rem;
  text-align: center;
}
