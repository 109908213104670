.formContainer {
  margin: 0 -2rem -2rem -2rem;
  max-height: 75vh;
  overflow: auto;
  padding: 2rem;
}

.label {
  margin-bottom: 1rem;
}

.grid {
  margin-top: 1rem;
}

.label textarea {
  height: 7rem;
  padding: 1rem;
}

div[class*='style_dropzone'] {
  padding: 1rem 1rem 0.5rem 1rem;
}

.breadcrumb {
  left: 1rem;
  margin: 0;
  position: absolute;
  top: 1rem;
}
