.icon {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.icon.spin {
  animation: spin 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
