.headerSnippet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.headerSnippet h2 {
  color: var(--color-grey);
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
}

.descriptionSnippet {
  color: var(--color-grey);
  font-size: 0.9rem;
  font-weight: normal;
  margin: 0;
}

.copyButton {
  user-select: none;
  color: var(--layout-primary-color);
}

.rightHeaderContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.rightHeaderContainer div {
  margin-right: 1.5rem;
  border-radius: 5px;
  border: solid 2px var(--layout-primary-color);
  padding: 0.5rem;
  transition: transform 0.1s ease-in-out;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
}

.rightHeaderContainer div:hover {
  transform: scale(0.98);
}

.dividerSnippet {
  background-color: #dfdfdf;
}
