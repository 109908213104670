.subtitleContainer {
  position: absolute;
  bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 90%;
  text-align: center;
  color: white;
  font-weight: 600;
  user-select: none;
  /* display: none; */
  transition: bottom 0.2s ease-in-out;
  font-size: 15px;
  line-height: 25px;
}

.subtitle {
  background-color: rgba(15, 15, 15, 0.68);
  border-radius: 9px;
  padding: 7px 9px;
  display: inline-block;
}
