.container {
  white-space: pre-wrap;
}

.readMoreContainer {
  font-weight: bold;
  color: var(--layout-primary-color);
  cursor: pointer;
  margin-left: 0.5rem;
  white-space: pre-wrap;
}
