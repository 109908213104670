.uploadList {
  background-color: var(--neutral-50);
  list-style: none;
  padding: 0.25rem;
  max-height: 30vh;
  overflow: auto;
}

.uploadListItem {
  background-color: var(--white);
  border: 1px solid var(--neutral-100);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: none;
  padding: 1rem;
}

.mt {
  margin-top: 0.5rem;
}

.mb {
  margin-bottom: 0.5rem;
}

.uploadListItem:not(:last-child) {
  margin-bottom: 1rem;
}

.uploadListItem svg {
  margin-right: 0.5rem;
}

.hint {
  font-size: 0.8rem;
  font-style: italic;
}

.itemPending {
  color: var(--tertiary-blue-200);
}

.itemWorking {
  color: var(--tertiary-blue-200);
}

.itemEnded {
  color: var(--success-green-200);
}

.itemFailed {
  color: var(--error-red-300);
}
