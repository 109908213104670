.alertContainer {
  white-space: pre;
}

.label,
.alertContainer,
.labelRef {
  margin-top: 0.5rem;
}

.labelRef label {
  margin-bottom: -0.4rem;
}
