.hint {
  display: flex;
  align-items: center;
  color: var(--color);
}

.hint.background {
  display: flex;
  align-items: center;
  background-color: var(--color);
  color: #fff;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  box-shadow: 0 3px 20px 0 rgb(0 0 0 / 5%);
  border-radius: var(--border-radius-block);
}

.hint.error {
  --color: var(--color-error);
}

.hint.warning {
  --color: var(--color-warning);
}

.hint.success {
  --color: var(--color-success);
}

.hint.info {
  --color: var(--tertiary-blue-600);
}

.hint.normal {
  --color: var(--layout-primary-color);
  font-style: italic;
  font-size: 0.7rem;
}

.hint > svg {
  margin-right: 0.5rem;
}
