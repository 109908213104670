.controls {
  background-color: #191919;
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: flex;
}

.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  vertical-align: middle;
  width: 50%;
  text-align: left;
}
.left span {
  margin-right: 12px;
  cursor: pointer;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  vertical-align: middle;
  width: 50%;
  text-align: right;
}

.right span {
  margin: 0 6px;
  cursor: pointer;
}

.bigIcon {
  font-size: 19px;
}

.text {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  color: rgb(187, 187, 187);
  width: 125px;
  margin: 0px 10px;
  text-align: center;
  font-family: var(--main-font);
}

.icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  flex-shrink: 0;
  user-select: none;
  margin: 0 5px;
  vertical-align: middle;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.icon:hover {
  opacity: 0.5;
}

.disabled,
.disabled:hover {
  cursor: not-allowed;
  opacity: 0.2;
}

.icon svg {
  fill: #fff;
  vertical-align: top;
  width: 24px;
  height: 24px;
}

.zoomInput {
  margin-left: 5px;
  vertical-align: middle;
}

.backButton {
  transform: scaleX(-1);
}

.selectTimeline {
  width: 100%;
  position: relative;
  height: 100%;
}

.select {
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}

.select:focus {
  outline: 0;
}
