.notificationContainer {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-right: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 110;
}

.notificationModal {
  transition: transform 0.65s;
}

.notificationModal.hide {
  transform: translateX(150%);
}

.notificationModal.show {
  transform: translateX(0);
}
