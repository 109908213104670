.row {
  flex-wrap: nowrap;
  min-width: calc(100% - 3rem);
  position: relative;
  width: fit-content;
}

.disabledRowElement {
  opacity: 0.33;
  cursor: not-allowed;
}

.disabledEdit {
  opacity: 0.33;
  cursor: not-allowed;
}

.hintRow {
  bottom: 0.5rem;
  position: absolute;
  left: 0.5rem;
}

.selectElement {
  width: 90%;
}

.importRow {
  flex: 0.7 !important;
  min-width: 8rem !important;
}

.idRow {
  flex: 0.2 !important;
  min-width: 3rem !important;
}

.headerCol {
  padding: 0.2em !important;
}

.headerCol,
.rowElement {
  min-width: 12rem !important;
}

.errorRow {
  box-shadow: 0px 10px 20px 0 rgb(0 0 0 / 5%);
  margin-top: -1rem;
}

.rowElement {
  display: block;
  justify-content: center;
  margin: inherit;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0.2em;
  align-self: center;
}

.blockTab {
  margin-bottom: 5em;
}

.switch {
  margin-left: auto;
  margin-right: auto;
}

.openCell {
  -webkit-line-clamp: inherit !important;
}

.warningTag {
  color: white;
  z-index: 1;
}

.warningContainer {
  z-index: 1;
  position: relative;
}

.warningTitle {
  color: var(--color-warning);
}

.warningIcon {
  color: var(--color-warning);
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto !important;
  height: 80% !important;
  opacity: 0.1;
}
