.button {
  width: 3.3rem;
  height: 3.3rem;
  margin: 0.75rem;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  border-radius: var(--border-radius-button);
  position: relative;
  user-select: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  /* background: var(--layout-primary-color); */
  border: solid 2px transparent;
}

.addIcon {
  padding: 0.5rem;
}

.longText {
  bottom: 0 !important;
  word-break: break-word;
}

.title {
  display: block;
  width: 100%;
  font-size: 0.5rem;
  text-align: center;
  color: var(--skp-color-neutral-700);
  font-weight: 900;
  text-transform: uppercase;
  position: absolute;
  bottom: 0.25rem;
}

.icon {
  width: 100%;
  height: 1.9rem;
  top: 0.35rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  display: block;
  position: absolute;
  font-size: 21px;
}

.icon > svg {
  width: 100%;
  height: 100%;
}

.button.selected {
  background-color: var(--layout-primary-color) !important;
  box-shadow: none;
}

.titleSelected {
  color: white !important;
}

.bigIcon {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* MEDIUM */
.medium .title {
  font-size: 0.65rem;
  font-weight: 600;
}

.medium .icon {
  height: 2.5rem;
}

.medium.button {
  width: 4.3rem;
  height: 4.3rem;
  margin: 0.75rem;
}

/* LARGE */
.large .title {
  font-size: 0.92rem;
  font-weight: 600;
}

.large .icon {
  top: 0.55rem;
  height: 3rem;
}

.large.button {
  width: 5.3rem;
  height: 5.3rem;
  margin: 0.75rem;
}
