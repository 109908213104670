.timeline {
  position: absolute;
  top: 65%;
  left: 0px;
  right: 0px;
  height: 35%;
  background-color: #202020;
  user-select: none;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.panel {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  user-select: none;
}

.container {
  position: absolute;
  top: 80px;
  bottom: 0px;
  width: 100%;
  overflow: auto;
  user-select: none;
}

.container.scroll {
  overflow: hidden;
}

.container::-webkit-scrollbar,
.container::-webkit-scrollbar {
  width: 15px;
}

.container::-webkit-scrollbar-thumb,
.container::-webkit-scrollbar-thumb {
  min-height: 50px;
  background-color: hsl(0, 0%, 40%);
  border: 3px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
  cursor: pointer;
}

.container::-webkit-scrollbar-track,
.container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.content {
  height: 100%;
  user-select: none;
}

.bodyContainer {
  display: flex;
  height: 65%;
  position: absolute;
}

.warningBodyContainer {
  height: calc(65% - 32px);
}

.bodyContainer > div:first-child {
  width: 35%;
}

.bodyContainer > div {
  width: 65%;
}

.bodyContainerVertical > div {
  width: auto;
  flex: 2;
}
