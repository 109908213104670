.tagTitle {
  margin-bottom: 0;
  font-weight: bold;
  color: var(--layout-primary-color);
}

.tags {
  margin: 0.5rem 0;
}

.tagsContainer {
  margin-bottom: 0.5rem;
}

.tagElement {
  position: relative;
}

.hidden {
  visibility: hidden;
}

.copied {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
