/* TODO Christopher maybe merge with AppConten ? */

.content {
  top: 3.8rem;
  height: calc(100vh - 3.8rem);
  position: fixed;
  z-index: 0;
  transition: width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
  background: var(--main-background-color);
  overflow: auto;
  box-sizing: border-box;
}

.content::-webkit-scrollbar {
  width: 15px;
}

.content::-webkit-scrollbar-thumb {
  min-height: 50px;
  background-color: var(--main-scrollbar-color);
  border: 3px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
  cursor: pointer;
}

.content::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* Following classes are apply based on menu status, they will change width of content area */

.navHidden {
  margin-right: 0rem;
  width: 100%;
}

.navLarge {
  margin-right: 17.95rem;
  width: calc(100% - 17.95rem);
}

.navSmall {
  margin-left: 6.2rem;
  width: calc(100% - 6.2rem);
}

/* Prepare responsive */

@media (max-width: 1200px) {
  .navLarge,
  .navSmall {
    margin-left: 0;
    width: 100%;
  }
}

.content.none {
  padding: 0;
}

.commitHash {
  position: fixed;
  bottom: 0;
  right: 0;
  color: rgba(0, 0, 0, 0);
  padding: 10px;
  margin-right: 10px;
  line-height: 14px;
  font-weight: 600;
  font-size: 14px;
  user-select: none;
  pointer-events: none;
  z-index: 9999999;
}

.commitHash.light {
  color: rgba(255, 255, 255, 0.15);
}

.commitHash.dark {
  color: rgba(0, 0, 0, 0.15);
}
