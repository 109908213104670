/* This CSS will reset default HTML/HEAD style */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('/node_modules/@skeepers/skeepers-ui-core/dist/skeepers-ui/skeepers-ui.css');

html {
  /** SKEEPERS COLORS **/
  --neutral-20: var(--skp-color-neutral-50);
  --neutral-50: var(--skp-color-neutral-50);
  --neutral-100: var(--skp-color-neutral-100);
  --neutral-200: var(--skp-color-neutral-200);
  --neutral-300: var(--skp-color-neutral-300);
  --neutral-400: var(--skp-color-neutral-400);
  --neutral-500: var(--skp-color-neutral-500);
  --neutral-600: var(--skp-color-neutral-600);
  --neutral-700: var(--skp-color-neutral-700);
  --neutral-800: var(--skp-color-neutral-800);
  --neutral-900: var(--skp-color-neutral-900);
  --primary-coral-50: var(--skp-color-primary-50);
  --primary-coral-100: var(--skp-color-primary-100);
  --primary-coral-200: var(--skp-color-primary-200);
  --primary-coral-300: var(--skp-color-primary-300);
  --primary-coral-400: var(--skp-color-primary-400);
  --primary-coral-500: var(--skp-color-primary-500);
  --secondary-yellow-100: #fff0c2;
  --secondary-yellow-200: #ffdb70;
  --tertiary-blue-100: #acd7f1;
  --tertiary-blue-200: #75bce7;
  --tertiary-blue-600: #185f8b;
  --warning-orange-100: var(--skp-color-warning-100);
  --warning-orange-200: var(--skp-color-warning-200);
  --warning-orange-500: var(--skp-color-warning-500);
  --error-red-300: var(--skp-color-error-300);
  --error-red-500: var(--skp-color-error-500);
  --success-green-100: var(--skp-color-success-100);
  --success-green-200: var(--skp-color-success-200);
  --success-green-500: var(--skp-color-success-500);
  --white: var(--skp-color-white);
  --black: var(--skp-color-black);

  /** BO COLORS **/
  --main-background-color: var(--neutral-100);
  --main-selection-color: var(--neutral-200);
  --main-scrollbar-color: var(--neutral-200);
  --main-font: 'roboto', Arial, sans-serif;
  --layout-primary-color: var(--primary-coral-500);
  --layout-secondary-color: var(--primary-coral-300);
  --layout-tertiary-color: var(--primary-coral-400);
  --layout-box-background-color: #fff;
  --layout-box-title-color: var(--neutral-600);
  --layout-box-subtitle-color: var(--layout-primary-color);
  --layout-box-artwork-color: var(--layout-primary-color);
  --input-focused-background-color: var(--primary-coral-50);
  --input-background-color: var(--neutral-50);
  --layout-nav-text-color: #fff;
  --layout-nav-selected-text-color: var(--layout-tertiary-color);
  --picto-color: var(--primary-coral-500);
  --video-text-color-secondary: var(--neutral-400);
  --navbar-color: var(--neutral-50);

  --tag-green: var(--skp-color-success-400);
  --tag-yellow: var(--secondary-yellow-200);
  --tag-yellow-secondary: var(--secondary-yellow-100);
  --tag-orange: var(--warning-orange-200);
  --tag-orange-secondary: var(--warning-orange-100);
  --tag-red: var(--error-red-300);
  --tag-grey: var(--skp-color-neutral-100);
  --tag-blue: var(--tertiary-blue-200);
  --tag-blue-secondary: var(--tertiary-blue-100);

  --color-grey: var(--neutral-800);
  --color-green: var(--success-green-200);
  --color-orange: var(--warning-orange-200);
  --color-info: var(--neutral-500);
  --color-disabled: var(--neutral-400);

  --color-error: var(--error-red-500);
  --color-warning: var(--warning-orange-500);
  --color-success: var(--success-green-500);
  --border-radius-button: 4px;
  --border-radius-block: 10px;
  --height-input: 2.5rem;
  --height-large-input: 7.5rem;
  --height-xl-input: 15rem;
  --height-xxl-input: 22.5rem;

  /** NEW BO VARIABLESS **/
  --solutions-background-color: white;
  --solutions-color: var(--layout-primary-color);

  --block-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  --block-border-radius: 4px;
}

:global(html.dark) {
  --main-background-color: #40444b;
  --main-selection-color: rgba(47, 49, 54, 0.3);
  --main-scrollbar-color: #2f3136;
  --layout-primary-color: #202225;
  --layout-secondary-color: #2f3136;
  --layout-tertiary-color: #292b2f;
  --layout-nav-selected-text-color: #eee;
  /*--layout-box-background-color: hsla(218, 8%, 25%, 1);
  --layout-box-title-color: #ececec;
  --layout-box-subtitle-color: #d3d3d3;*/
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 100%;
  font-family: var(--main-font);
  background: var(--main-background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100vh;
  background: var(--main-background-color);
  font-family: var(--main-font);
}

::selection {
  background: var(--main-selection-color);
}

::-moz-selection {
  background: var(--main-selection-color);
}
