.previewContainer {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black repeating-conic-gradient(#d3d3d3 0% 25%, transparent 0% 50%) 50% / 20px 20px;
  background-position: top left;
  overflow: hidden;
}

.bordered {
  outline: 3px solid #4286f4;
  outline-offset: -3px;
}

.logo {
  position: absolute;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.logoMovable {
  cursor: move;
}

.resizer {
  color: white;
  position: absolute;
  background-color: #4286f4;
  width: 0.6rem;
  height: 0.6rem;
}

.resizerTopLeft {
  cursor: nwse-resize;
}

.resizerTopRight {
  cursor: nesw-resize;
}

.resizerBottomLeft {
  cursor: nesw-resize;
}

.resizerBottomRight {
  cursor: nwse-resize;
}

.hidden {
  display: none;
}
