.colorPickerContainer {
    width: 28rem;
}

.flexGap {
    gap: 40px;
}

@media (max-width: 800px) {
    .colorPickerContainer {
        width: 100%;
    }
}