.action {
  display: block;
  height: 1.2rem;
  overflow: hidden;
}

.container:not(.disabled):hover {
  opacity: 0.8;
}

.icon {
  height: 0.8rem;
  width: 0.8rem;
}

.label {
  vertical-align: middle;
  user-select: none;
}

.disabled {
  cursor: default;
}

@media (max-width: 950px) {
  .icon {
    height: 1rem;
    width: 1rem;
    margin: 0;
  }

  .label {
    display: none;
  }
}
