.select {
  width: 100%;
  height: 100%;
  font-family: var(--main-font);
  font-size: 1rem;
  background: var(--input-background-color);
  border-radius: var(--border-radius-button);
}

.select :global(.select__value-container--has-value),
.select :global(.select__value-container) {
  height: 100%;
}
.select :global(.select__single-value) {
  font-weight: 500;
}
.select :global(.select__control) {
  height: 100%;
}
.select :global(.select__input) {
  display: flex !important;
  height: 100%;
}

.select :global(.select__placeholder) {
  font-weight: normal;
}

.select input {
  font-family: var(--main-font);
}

.select :global(.select__control) {
  border-radius: var(--border-radius-button);
  background-color: transparent;
  min-height: 20px;
  border: solid 1px transparent;
  color: var(--layout-primary-color);
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.select :global(.select__control):hover {
  border-color: transparent;
}

.select :global(.select__menu) {
  overflow: hidden;
}
.select :global(.select__indicators) {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.select :global(.select__value-container) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.select :global(.select__menu-list) {
  padding: 0;
}

.select :global(.select__option) {
  line-height: 2rem;
  color: hsl(0, 0%, 20%);
  font-weight: 500;
}

.select :global(.select__control--is-focused) :global(.select__dropdown-indicator) {
  color: var(--layout-primary-color);
}

.select :global(.select__indicator-separator) {
  visibility: hidden;
}

.select :global(.select__control--is-focused):hover {
  border-color: var(--layout-primary-color);
}

.select :global(.select__option--is-focused) {
  color: black;
  background-color: var(--input-focused-background-color);
}

.select :global(.select__control--is-focused) {
  border-color: var(--layout-primary-color);
  background-color: var(--input-focused-background-color);
  box-shadow: none;
}
/* Need to be after .select__control--is-focused to work fine */
.select :global(.select__option--is-selected) {
  color: #ffffff;
  background-color: var(--layout-primary-color);
  font-weight: 500;
}

.select :global(.select__placeholder) {
  font-style: italic;
}
