.cell {
  color: #7e7e7e;
  font-weight: 600;
  justify-content: center;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.cell_picture {
  display: flex !important;
}

.cellTag {
  -webkit-line-clamp: 3;
}

.picture {
  width: 5rem;
  height: 5rem;
  position: relative;
  margin: 0 1rem;
}
