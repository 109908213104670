.uploadInputContainer {
  width: 28rem;
}

.flexGap {
  gap: 40px;
}

@media (max-width: 800px) {
  .uploadInputContainer {
    width: 100%;
  }
}