.selector {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem 0;
  padding-bottom: 4px;
  border-bottom: solid 2px var(--input-background-color);
}

.helper {
  text-align: center;
  margin: 0 0 1rem 0;
  font-weight: 600;
}

.selectorItem {
  cursor: pointer;
  margin: 0 10px;
  font-weight: 600;
}

.selected {
  color: var(--layout-primary-color);
  position: relative;
}

.selected::after {
  display: block;
  content: '';
  width: 100%;
  height: 3px;
  background: var(--layout-primary-color);
  position: absolute;
  bottom: -0.3rem;
  left: 0;
}

.resizerLogosContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  height: 4rem;
}

.resizerLogoContainer {
  position: relative;
  display: flex;
  border: 1px solid transparent;
}

.resizerLogoContainer:not(:last-child) {
  margin-right: 1rem;
}

.resizerLogoPreview {
  max-width: 4rem;
  max-height: 4rem;
}

.action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.action,
.action svg,
.resizerLogoContainer {
  transition: all 0.2s ease-in-out;
}

.resizerLogoContainer:hover {
  border: 1px solid var(--layout-primary-color);
}

.action svg {
  opacity: 0;
  font-size: 1.2rem;
}

.action:hover svg {
  opacity: 1;
}

.action:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
