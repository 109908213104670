.root {
  max-width: 300px;
  overflow-y: scroll;
  justify-content: center;
  flex-flow: row wrap;
  align-content: flex-start;
  background: rgb(32, 32, 32);
  color: white;
  padding-top: 1em;
}

.rootVertical {
  max-width: 350px;
}

.root::-webkit-scrollbar,
.root::-webkit-scrollbar {
  width: 15px;
}

.root::-webkit-scrollbar-thumb,
.root::-webkit-scrollbar-thumb {
  min-height: 50px;
  background-color: hsl(0, 0%, 40%);
  border: 3px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
  cursor: pointer;
}

.root::-webkit-scrollbar-track,
.root::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.container {
  margin-left: 1em;
}

.speedContainer {
  display: flex;
  justify-content: space-evenly;
}

.speedRangeContainer {
  flex: 2;
  background: rgba(50, 50, 50);
  line-height: 30px;
  border-radius: 2.12px;
  margin-bottom: 6px;
  height: 30px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  user-select: none;
  margin-right: 1em;
}

.speedIconsContainer {
  width: 30px;
  height: 30px;
  background: rgba(70, 70, 70);
  display: flex;
  align-items: center;
  justify-content: center;
}

.speedIconsContainer:hover {
  background: rgba(90, 90, 90);
  cursor: pointer;
}

.speedIcons {
  width: 20px;
  height: 20px;
}

.btn {
  padding: 0.4rem 1rem;
  text-align: center;
}

.btn span {
  width: 100%;
}

.volumeContainer {
  display: flex;
  justify-content: space-evenly;
}

.volumeInput {
  flex: 2;
  margin-right: 1em;
  user-select: none;
}

.smartCutContainer {
  margin: 2rem 4rem 0 4rem;
}

.btnContainer {
  flex: 1;
}

.divider {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.filename {
  text-align: center;
}

.infosContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 25%;
}

.infosContainer h2 {
  display: inline-block;
}
