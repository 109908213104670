.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: var(--skp-color-neutral-900);
  font-weight: 600;
  transition: color 0.2s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*user-select: none;*/
}

.info {
  display: flex;
  align-items: center;
}

.infoIcon {
  margin-left: 0.5rem;
}

.actions {
  display: flex;
}

.labelContainer .actions span {
  margin-right: 0.8rem;
}
.labelContainer .actions span:last-of-type {
  margin-right: 0px;
}
.labelContainer .actions svg {
  margin-right: 0.2rem;
}

.group {
  align-self: stretch;
  height: 100%;
}

@media screen and (max-width: 950px) {
  .labelContainer .actions span:last-of-type {
    margin-right: 0px;
  }
}
