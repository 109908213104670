.avatar {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--layout-primary-color);
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
}

.avatarGood {
  border: 3px solid var(--color-success);
}

.avatarBad {
  border: 3px solid var(--color-error);
}

.avatarSimple {
  border: 3px solid white;
}
