.optionTitleAndIcon {
  padding: 0.8rem;
  box-sizing: border-box;
  font-weight: bold;
  color: var(--layout-primary-color);
  gap: 0.5rem;
}

.subContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionFinalised {
  font-weight: normal;
  font-size: 0.8rem;
}

.divider {
  margin: 0;
  border: 1px solid var(--layout-primary-color);
}

.currentStepDivider {
  width: 75%;
}

.comingStep {
  color: var(--color-info);
  opacity: 0.5;
}

.comingStepDivider {
  visibility: hidden;
}

.sectionContainer {
  width: 100%;
  padding: 2rem 3rem 0 3rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sectionContainer .title {
  color: var(--layout-primary-color);
  margin-bottom: 1rem;
  font-weight: 700;
}

.sectionContainer .description {
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 2rem;
}

@media (max-width:600px) {
  .sectionContainer {
    padding: 24px 24px 0 24px;
  }
}


/** RIGHT PART **/

.rightPart {
  width: 100%;
}

.rightPart form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 4rem 0;
  box-sizing: border-box;
}


@media (max-width: 1115px) {
  .rightPart form {
    gap: 24px;
  }
}

@media (max-width: 650px) {
  .rightPart .responsiveButtons {
    flex-direction: column-reverse;
    gap: 8px;
    padding: 0 48px;
  }

  .responsiveButtons > span {
    width: 100%;
    margin-left: 0!important;
  }
}

@media (max-width: 650px) {
  .rightPart .responsiveButtons {
    padding: 0 24px;
  }
}

/** LEFT PART **/

.leftPart {
  width: 18rem;
  background-color: var(--main-background-color);
  min-height: 40rem;
}

.leftPart .titleAndDescriptionContainer {
  padding: 2rem 1rem 0 1rem;
  margin-bottom: 2rem;
}

.leftPart .templateTitle {
  color: var(--layout-primary-color);
  font-size: 1rem;
}