.container {
  height: 150px;
}

.icon {
  display: block;
  height: 2rem !important;
  width: 2rem !important;
}

.textContainer {
  text-align: center;
}

.text {
  font-weight: bold;
}
