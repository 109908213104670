@import url('./css/tables.css');

.custom-scrollbar::-webkit-scrollbar {
    width: 15px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    min-height: 50px;
    background-color: var(--main-scrollbar-color);
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
    cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
}
