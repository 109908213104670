.action {
  width: 100%;
}

.action:hover {
  color: var(--layout-primary-color);
}

.keyContainer {
  flex: 0.3 !important;
}

.valueContainer {
  flex: 0.7 !important;
  overflow: auto !important;
  text-align: left !important;
}

.cell {
  text-align: initial !important;
}

.htmlPreview {
  min-height: 5rem;
}
