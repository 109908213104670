.marginButton {
  margin-right: 1rem;
}

.resizerLogosContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  height: 4rem;
}

.resizerLogoContainer {
  position: relative;
  display: flex;
  border: 1px solid transparent;
}

.resizerLogoContainer:not(:last-child) {
  margin-right: 1rem;
}

.imageContainer {
  display: flex;
  align-items: center;
}

.resizerLogoPreview {
  max-width: 4rem;
  max-height: 4rem;
}

.action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.action,
.action svg,
.resizerLogoContainer {
  transition: all 0.2s ease-in-out;
}

.action svg {
  opacity: 0;
  font-size: 1.2rem;
}

.action:hover svg {
  opacity: 1;
}

.action:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.resizerLogoContainer:hover {
  border: 1px solid var(--layout-primary-color);
}
