.input {
  box-sizing: border-box;
  padding: 0.6rem;
  border-radius: var(--border-radius-button);
  font-size: 1rem;
  background: rgb(245, 245, 245);
  margin: 0.5rem 0;
  font-family: var(--main-font);
  color: black;
  display: block;
  width: 100%;
  border: solid 1px transparent;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  position: relative;
  height: var(--height-input);
}

.input:focus {
  outline: none;
}

.input:focus:not([readonly]) {
  border: solid 1px var(--layout-primary-color);
  background-color: var(--input-focused-background-color);
}

.input::placeholder {
  font-style: italic;
  color: rgb(134, 134, 134);
}

.text {
  min-height: 5rem;
  height: calc(100% - 2.5rem);
  resize: vertical;
}

.addButton {
  background-color: var(--layout-primary-color);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2.5rem;
  text-align: center;
  border-top-right-radius: var(--border-radius-button);
  border-bottom-right-radius: var(--border-radius-button);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.readOnly {
  background-color: var(--color-disabled);
  cursor: unset;
}

.addInput {
  position: relative;
}

.icon {
  width: 2rem;
}

.trashIcon {
  width: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.list {
  padding: 0;
  margin: 0 0 1rem 0;
}

.line {
  display: flex;
  background-color: white;
  width: calc(100%);
  box-shadow: 0 3px 6px rgba(114, 144, 255, 0.13);
  margin-top: 1rem;
  border-radius: var(--border-radius-button);
  overflow: hidden;
  /* min-height: 4rem; */
}

.linePosition {
  display: flex;
  background-color: var(--layout-primary-color);
  width: 1.8rem;
  cursor: pointer;
  color: white;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}

.lineInput {
  width: calc(100% - 1rem);
  border: none;
  font-size: 0.9rem;
  margin-left: 1rem;
  resize: none;
}

.lineInput[readonly] {
  outline: none;
}

.lineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4.5rem);
  vertical-align: top;
  margin: 1rem 0;
}

.lineTitle {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.error {
  border: solid 1px var(--color-error);
}
