.margin-none {
  margin: 0;
}

.margin-small > *:not(:first-child) {
  margin-left: 0.5rem;
}

.margin-medium > *:not(:first-child) {
  margin-left: 1rem;
}

.margin-big > *:not(:first-child) {
  margin-left: 1.5rem;
}

.margin-huge > *:not(:first-child) {
  margin-left: 2rem;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.position-flex-end {
  justify-content: flex-end;
}

.position-flex-start {
  justify-content: flex-start;
}

.position-center {
  justify-content: center;
}

.position-space-between {
  justify-content: space-between;
}

.position-space-around {
  justify-content: space-around;
}

.position-space-evenly {
  justify-content: space-evenly;
}

.align-flex-start {
  align-items: flex-start;
}

.align-flex-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.align-baseline {
  align-items: baseline;
}

.wrap-nowrap {
  flex-wrap: nowrap;
}

.wrap-wrap {
  flex-wrap: wrap;
}

.wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}
