.button {
  --button-color: var(--layout-primary-color);
  border: solid 2px var(--button-color);
  border-radius: var(--border-radius-button);
  box-sizing: border-box;
  cursor: pointer;
  font-family: var(--main-font);
  font-size: 1.05rem;
  font-weight: 600;
  height: 2.5rem;
  overflow: hidden;
  padding: 0.4rem 2.6rem;
  transition: all 0.3s ease-in-out;
  text-align: center;
  position: relative;
  user-select: none;
  outline: none;
  display: flex;
  align-items: center;
}

.loading {
  cursor: not-allowed;
}

.hidden {
  visibility: hidden;
}

.button:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
}

.primary {
  background-color: var(--button-color);
  color: var(--white);
}

.secondary {
  background-color: transparent;
  border-color: var(--skp-color-neutral-500);
  color: var(--skp-color-neutral-700);
}

.tertiary {
  background-color: transparent;
  border: none;
  color: var(--neutral-900);
  font-size: 0.9rem;
  padding: 0.1rem 0.8rem;
}

.tertiary svg {
  font-size: 0.8rem;
  margin-right: 0.4rem;
}

.secondary:hover,
.primary:hover {
  opacity: 0.5;
}

.disabled {
  border-color: var(--color-disabled);
  cursor: not-allowed;
}

.disabled:hover {
  border-color: var(--color-disabled);
  box-shadow: none;
  transform: none;
}

.primary.disabled {
  background-color: var(--color-disabled);
  color: var(--color-white);
}

.secondary.disabled {
  background-color: transparent;
  color: var(--color-disabled);
}

.outline {
  background-color: transparent;
  border-color: var(--button-color);
  color: var(--button-color);
}