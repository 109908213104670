.spinner {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  width: 70px;
  height: 40px;
  transition: all 0.2s ease-in-out;
}

.spinner div {
  position: absolute;
  top: 15px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.secondary div {
  background: var(--layout-primary-color);
}

.tertiary div {
  background: var(--neutral-400);
}

.spinner div:nth-child(1) {
  left: 8px;
  animation: spinner1 0.6s infinite;
}

.spinner div:nth-child(2) {
  left: 8px;
  animation: spinner2 0.6s infinite;
}

.spinner div:nth-child(3) {
  left: 32px;
  animation: spinner2 0.6s infinite;
}

.spinner div:nth-child(4) {
  left: 56px;
  animation: spinner3 0.6s infinite;
}

@keyframes spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
