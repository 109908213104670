.videoLimitsContainer {
  margin-bottom: 1.5rem;
}

.extractInfos {
  text-align: center;
}

.infoCustomText {
  margin-bottom: 1rem;
}

.textInputContainer {
  margin-bottom: 2rem;
}
