.list {
  padding: 0;
  list-style: none;
}

.list.suppress {
  margin: 0 -1.5rem; /* Suppress Block side padding */
}

.list > .row:nth-child(2n) {
  background-color: var(--main-background-color);
}
