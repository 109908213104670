.checklist {
  margin-bottom: 0.5rem;
}

.title {
  font-weight: 700;
  color: var(--layout-primary-color);
  text-align: center;
  font-size: 1.2rem;
}
