.fullWidth {
  width: 100%;
}

.blockTitle {
  font-weight: 600;
}

.resumeContainer {
  background: var(--layout-box-background-color);
  border: 1px solid var(--skp-color-neutral-200);
  border-radius: var(--border-radius-block);
  margin: 0.5rem 0.5rem 1rem 0.5rem;
}

.resume {
  color: var(--skp-color-neutral-700);
  max-height: 50vh;
  padding: 1rem;
  overflow: scroll;
}

.resumeText {
  margin-bottom: 1rem;
}

.resumeText span, .content span {
  color: var(--skp-color-neutral-700);
}

.contentTitle {
  border-bottom: 1px solid var(--skp-color-neutral-100);
  margin: 0.5rem 0;
}

.briefTitle {
  font-size: 1.1rem;
  padding: 0.5rem 0;
  width: 100%;
}

.inputs {
  margin: 0.5rem;
}

.titles {
  margin-bottom: 1rem;
  font-weight: 600;
  border-bottom: 2px solid var(--skp-color-neutral-200);
  overflow: hidden;
}

.selected {
  background-color: var(--main-background-color);
  color: var(--layout-primary-color);
}
