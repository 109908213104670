.player {
  position: relative;
  grid-area: player;
}

.videoContainer {
  position: relative;
}

.video {
  width: 100%;
  outline: none;
  border-radius: 0.5rem;
}

.verticalVideo {
  max-height: 25rem;
}

.cardsContainer {
  position: absolute;
  margin: inherit;
  padding: inherit;
  left: 0;
  top: 0;
}

.card {
  padding: 0.2rem 0.2rem 0.2rem 0.4rem;
  border-radius: 6px;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.7);
  border-left: 6px solid var(--layout-primary-color);
}

.cardTitle {
  margin: 0;
  font-size: 0.85rem;
}

.cardDescription {
  margin: 0;
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
}
