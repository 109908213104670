.publish {
  font-size: 0.8rem;
  font-style: italic;
}

.dateHeaderColor {
  color: var(--layout-primary-color);
}

.containerTitle {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.changelogTitle {
  font-size: 1rem;
  user-select: none;
  color: var(--layout-primary-color);
  margin: 0.4rem 0;
  text-transform: uppercase;
}

.changelogHR {
  flex: 1;
  height: 1px;
  margin-left: 0.5rem;
  border: none;
  background-color: var(--layout-primary-color);
}

.changelogDetailTitle {
  font-weight: bold;
}

.changelogDetail {
  font-size: 1rem;
  user-select: none;
  margin-bottom: 0.5rem;
}
.list {
  list-style: none;
  padding-left: 10px;
}
