.spinner {
  position: absolute;
  top: calc(50%);
  right: 50%;
  transform: translate(-50%, 50%);
}

.dropzone {
  position: relative;
  border: 1px solid transparent;
  background-color: var(--input-background-color);
  padding: 2rem;
  margin: 0.5rem 0;
  border-radius: var(--border-radius-button);
  text-align: center;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  user-select: none;
  outline: none;
  min-height: calc(6.5rem + 4px);
}

.active {
  border-color: var(--layout-primary-color);
  background-color: var(--input-focused-background-color);
}

.p {
  color: var(--video-text-color-secondary);
}

.message {
  font-weight: 600;
  font-size: 1.05rem;
  margin: 0;
}

.or {
  line-height: 2rem;
  font-size: 0.8rem;
  font-style: italic;
}

.image {
  height: 100%;
  width: 100%;
  min-height: 8rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto;
}
.preview svg {
  font-size: 2rem;
}

.link {
  font-size: 0.7rem;
  font-style: italic;
  color: var(--layout-primary-color);
}

.link:hover {
  opacity: 0.8;
}

.hidden {
  width: 0;
}

.childrenContainer {
  margin-top: 10px;
}

.error {
  border: solid 1px var(--color-error);
}
