.keyContainer {
  flex: 0.3 !important;
}

.valueContainer {
  flex: 0.7 !important;
  overflow: scroll !important;
  text-align: left !important;
}

.screenshot {
  width: 100%;
}

.datePickerWrapper {
  margin: 0.5rem;
}
