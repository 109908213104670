.clock {
  top: 40px;
  background: #00ccff;
  width: 2px;
  height: 100%;
  position: absolute;
  display: inline-block;
  z-index: 9998;
  cursor: col-resize;
  pointer-events: none;
}

.time {
  width: 70px;
  height: 20px;
  line-height: 22px;
  margin-left: -34px;
  position: relative;
  background: #00ccff;
  color: #f9f9f9;
  border-radius: 2px;
  text-align: center;
  pointer-events: auto;
  font-size: 12px;
  user-select: none;
  cursor: pointer;
}

.time:after {
  top: 100%;
  left: 50%;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #00ccff;
  margin-left: -8px;
  user-select: none;
}
