.bgd {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;
  z-index: 9999;
  opacity: 0;
  transition: background-color 0.2s ease-in, opacity 0s linear, width 0s linear;
  transition-delay: 0s, 0.2s, 0.21s;
  background: rgba(0, 0, 0, 0);
  user-select: none;
  overflow: hidden;
}

.bgd.open {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  opacity: 1;
  transition: width 0s linear, opacity 0s linear, background-color 0.2s ease-in;
  transition-delay: 0s, 0.2s, 0.21s;
}

.pwindow {
  transition: all 0.25s ease-in;
  transform: scale(0.85);
  opacity: 0;
}

.open .pwindow {
  opacity: 1;
  transform: scale(1);
}

.window {
  background: #fff;
  border-radius: var(--border-radius-button);
  box-shadow: 0px 0px 10px 0px rgba(114, 114, 255, 0.05);
  transform: translate(-50%, -50%);
  width: calc(100% - 4rem);
  max-width: 700px;
  max-height: calc(100vh - 4rem);
  min-height: 5rem;
  overflow: auto;
  left: 50%;
  top: 50vh;
  position: fixed;
}

.windowPadding {
  padding: 2rem;
}

.marginList {
  margin: 0.25rem;
}

.titleContainer {
  margin-bottom: 2rem;
}

.title {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 0;
}

.title,
.subtitle {
  text-align: center;
  font-family: var(--main-font);
  padding: 0;
  margin-bottom: 0rem;
  user-select: none;
}

.subtitle {
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.closeIcon {
  position: absolute;
  right: 0.2rem;
  top: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  user-select: none;
  z-index: 5;
}

.closeIcon:hover {
  opacity: 0.8;
}

.closeIcon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 450px) {
  .window {
    height: calc(100vh - 4rem);
    border-radius: 0;
  }
}
