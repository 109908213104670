.bannerContainer {
  position: fixed;
  bottom: 1.5em;
  right: 1em;
  z-index: 5;
  display: flex;
}

.banner {
  background-color: var(--input-background-color);
  display: flex;
  justify-content: right;
  align-items: center;
  border-radius: var(--border-radius-block);
  box-shadow: 0 3px 20px 0 rgb(0 0 0 / 5%);
  padding: 0.5rem 1.5rem;
}

.banner > *:not(:first-child) {
  margin-left: 1.5rem;
}

.buttonTop {
  border-radius: var(--border-radius-block);
  padding: 0.5rem 1.1rem;
  margin-left: 1rem;
  height: 100% !important;
  width: inherit;
}
