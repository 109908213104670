.container {
  overflow: auto;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.container,
.container:visited,
.container:hover,
.container:active {
  color: inherit;
  text-decoration: none;
}
