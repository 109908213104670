.input {
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-button);
  font-size: 1rem;
  background: rgb(245, 245, 245);
  margin: 0.5rem 0;
  font-family: var(--main-font);
  color: black;
  display: block;
  width: 100%;
  min-width: 0;
  border: solid 1px transparent;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  height: var(--height-input);
}

.input:focus {
  outline: none;
}

.input:focus:not([readonly]):not(.error) {
  border: solid 1px var(--layout-primary-color);
  background-color: var(--input-focused-background-color);
}

.input::placeholder {
  font-style: italic;
  color: rgb(134, 134, 134);
}

.text {
  height: calc(9rem + 4px);
  resize: none;
}

.input:read-only {
  opacity: 0.5;
  cursor: not-allowed;
}

.error {
  border: solid 1px var(--color-error);
}

input:-webkit-autofill {
  background-color: var(--input-focused-background-color) !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}
