.header {
  display: flex;
  align-items: center;
  color: var(--layout-primary-color);
  cursor: pointer;
  font-size: 1rem;
}

.marginLeft {
  margin-left: auto;
}

.headerTitle {
  width: 100%;
}

.additionnalMargin {
  margin-right: 1rem;
}

.divider {
  margin: 1rem 0 1.5rem 0;
}
