.input {
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-button);
  font-size: 1rem;
  background: rgb(245, 245, 245);
  margin: 0.5rem 0;
  font-family: var(--main-font);
  color: black;
  display: block;
  width: 100%;
  min-width: 0;
  border: solid 1px transparent;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  height: var(--height-input);
}

.input:focus {
  outline: none;
}

.input:focus:not(.readOnly) {
  border: solid 1px var(--layout-primary-color);
  background-color: var(--input-focused-background-color);
}

.input[type='range'] {
  appearance: none;
  height: 10px;
  border-radius: var(--border-radius-button);
  border: 1px solid var(--layout-secondary-color) !important;
  opacity: 0.7;
  transition: opacity 0.2s;
  padding: 0;
  cursor: pointer;
}

.input[type='range']:hover {
  opacity: 1;
}

.input[type='range']::-webkit-slider-thumb {
  appearance: none;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: var(--layout-primary-color);
  cursor: pointer;
}

.input[type='range']::-moz-range-thumb {
  appearance: none;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: var(--layout-primary-color);
  cursor: pointer;
}

.input.readOnly {
  opacity: 0.5;
  cursor: not-allowed;
}

.rangeDisplay {
  display: flex;
  justify-content: space-between;
}
