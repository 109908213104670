.select {
  box-sizing: border-box;
  font-family: var(--main-font);
  font-size: 1rem;
  margin: 0.5rem 0;
}

.select.error :global(.select__control) {
  border: solid 1px var(--color-error);
}

.select input {
  font-family: var(--main-font);
}

.select :global(.select__indicator) {
  color: var(--neutral-900);
  transition: transform 0.2s ease-in-out;
}

.select :global(.select__control--is-focused) :global(.select__dropdown-indicator) {
  transform: rotateZ(180deg);
}

.select :global(.select__indicators) {
  box-sizing: border-box;
}

.select :global(.select__control) {
  background: var(--color-white);
  box-sizing: border-box;
  border: 1px solid var(--neutral-300);
  border-radius: var(--border-radius-button);
  color: var(--layout-primary-color);
  height: 2.625rem;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.select :global(.select__control--is-focused) :global(.select__dropdown-indicator) {
  color: var(--neutral-900);
}

.select :global(.select__indicator-separator) {
  visibility: hidden;
}

.select :global(.select__control--is-focused),
.select :global(.select__control--menu-is-open) {
  box-shadow: none;
  outline: 4px solid var(--neutral-100) !important;
}

.select :global(.select__placeholder) {
  color: var(--neutral-400);
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.select :global(.select__option--is-selected) {
  background-color: var(--neutral-50);
  color: var(--color-black);
  font-weight: 600;
}

.select :global(.select__option--is-focused) {
  background-color: var(--neutral-50);
}

:global(.select__menu-portal) {
  margin-top: -0.5rem;
  z-index: 9999 !important;
}

.select :global(.select__menu) {
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select :global(.select__menu-list) {
  z-index: 99;
  padding-top: 0;
}

.background {
  background-color: white;
  box-shadow: 0px 3px 6px 0px rgba(44, 40, 40, 0.11);
}

/* Custom option labels format */

.option {
  display: flex;
  align-items: center;
}

.option .icon {
  margin-right: 1rem;
  width: 1rem;
  text-align: center;
}

.option .label {
  flex-grow: 1;
}

span {
  word-break: break-word;
}
