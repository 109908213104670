.timer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.time {
  color: var(--layout-primary-color);
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  border: 5px solid var(--layout-primary-color);
  min-width: 100px;
  min-height: 100px;
  line-height: 100px;
  text-align: center;
  user-select: none;
}

.play {
  color: #000;
  cursor: pointer;
  font-size: 1.5rem;
}

.pause {
  color: #000;
  cursor: pointer;
  font-size: 1.5rem;
}

.disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}
