.marginButton {
  margin-right: 1rem;
}

.message {
  margin: 0;
}

.messageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
