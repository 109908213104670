.root {
  margin: 1rem 1rem 2rem 1rem;
}

.breadcrumb {
  color: var(--video-text-color-secondary);
  font-size: 0.9rem;
}

.action:hover {
  cursor: pointer;
  opacity: 0.9;
}
