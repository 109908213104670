.root {
  margin: 0.5rem 0;
}

.container {
  position: relative;
  display: flex;

  margin-bottom: 0.5rem;
  user-select: none;
  cursor: pointer;
}

.input {
  width: 0;
  appearance: none;
  outline: none;
  position: absolute;
}

.styledInput {
  position: relative;
  height: 1.2rem;
  min-width: 1.2rem;
  margin-right: 0.5rem;
  border: solid 2px;
}

.styledInput:hover {
  border-color: var(--skp-color-primary-500);
}

.checkedIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke-width: 4px;
}

.checkedIcon > svg {
  width: 50% !important;
}

.input[type='checkbox'] ~ .styledInput > .checkedIcon {
  height: 75%;
  width: 75%;
}

.input[type='radio'] ~ .styledInput > .checkedIcon {
  height: 50%;
}

.input[type='checkbox'] ~ .styledInput {
  border-radius: 0.2rem;
}

.input[type='radio'] ~ .styledInput {
  border-radius: 50%;
}

.input[type='checkbox']:checked ~ .styledInput {
  background-color: var(--layout-primary-color);
}

.input[type='checkbox']:checked:disabled ~ .styledInput {
  background-color: var(--color-disabled);
}

.checkedIcon > svg {
  display: block;
  height: 100%;
  width: 100%;
}

.boxNotChecked {
  border-color: var(--skp-color-neutral-500);
}

.boxChecked {
  border-color: var(--skp-color-primary-500);
}

.input:checked ~ .boxNotChecked {
  display: none;
}

.input:not(:checked) ~ .boxChecked {
  display: none;
}

.input:disabled ~ .boxNotChecked {
  border: solid 2px var(--skp-color-neutral-100);
}

.disabled,
.input:disabled ~ .label,
.input:disabled ~ .styledInput {
  border-color: var(--color-disabled);
  color: var(--video-text-color-secondary);
  cursor: unset;
}

.input:not(:disabled) ~  .styledInput:active,
.input:not(:disabled) ~  .styledInput:focus {
  outline: 3px solid var(--skp-color-primary-100);
}

.label {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
  display: block;
}
