.field {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.field > .name {
  flex: 1;
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: black;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field > .name > svg {
  margin: 0 0.5rem;
}

.field > .input {
  flex: 1;
  height: unset;
}

.field > .action {
  margin: 0 0.5rem;
}

.divider {
  margin: 1rem 0 2rem 0;
}
