.root {
  z-index: 10;
  background-color: var(--white);
  font-family: var(--main-font);
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  width: 100%;
  height: 3.8rem;
  /* overflow: hidden;*/
  user-select: none;
  top: 0;
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.helpCenter {
  color: var(--skp-color-neutral-900);
  gap: var(--skp-size-1) !important;
  margin-right: 1rem;
}

.helpCenter p:hover {
  color: var(--skp-color-neutral-700) !important;
}

.headerLeft {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 17.95rem;
  z-index: 1;
  margin-left: 2rem;
  position: relative;
}

.title {
  text-transform: uppercase;
  cursor: pointer;
  line-height: 3.8rem;
}

.headerRight {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.bugerMenuContainer {
  z-index: 13;
  color: white;
}

.user {
  margin-left: 2rem;
  margin-right: 0.5rem;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  height: 3.8rem;
  min-width: 12rem;
}

.iconChevronDown {
  transition: transform 0.2s ease-in-out;
  margin: 0 1rem;
}

.userMenu {
  display: none;
  position: absolute;
  top: 3.8rem;
  width: 100%;
  background: var(--white);
  line-height: 2.5rem;
  font-size: 0.8rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  overflow: auto;
  max-height: calc(100vh - 3.8rem);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);
}

.subMenu {
  display: flex;
  align-items: center;
  transition: background-color 0.1s ease-in-out;
  color: var(--skp-color-neutral-700) !important;
}

.subMenu:hover {
  background-color: var(--primary-coral-50);
}

.user:hover .iconChevronDown {
  transform: rotate(180deg);
}

.user:hover .userMenu,
.userMenu:hover {
  display: block;
}

.avatar {
  display: inline-block;
  vertical-align: middle;
  align-self: center;
  margin: 0 0.25rem;
}

.infosUserContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.8rem;
  color: var(--black);
}

.userName {
  margin: 0;
  font-size: 0.9rem;
  padding-right: 0.8rem;
}

.userMail {
  margin: 0.3rem 0 0 0;
  font-size: 0.6rem;
  padding-right: 0.8rem;
  font-weight: 600;
}

.iconContainer {
  display: flex;
  justify-content: center;
  border: 0px;
  align-self: center;
  margin: 0 0 0 0.4rem;
  cursor: pointer;
  position: relative;
  width: 3rem;
}

.number {
  font-family: var(--main-font);
  background-color: rgb(243, 156, 18);
  position: absolute;
  right: -0.1rem;
  top: -0.1rem;
  color: white;
  border-radius: 50%;
  width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.containerSelect {
  height: 55%;
  width: 20rem;
}

@media screen and (max-width: 875px) {
  .title {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .userName,
  .userMail,
  .title {
    display: none;
  }
  .user {
    min-width: unset;
  }
  .userMenu {
    width: 14rem;
    right: 1rem;
  }
}

@media screen and (max-width: 713px) {
  .containerSelect {
    width: 15rem;
  }
}

@media screen and (max-width: 629px) {
  .containerSelect {
    width: 10rem;
  }
}
