.root {
  display: flex;
  height: calc(100vh - 7rem);
  padding: 0 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  flex: 0.5 0.5;
  margin: 3rem 0;
  width: 50%;
}

.title,
.subtitle {
  user-select: none;
}

.title {
  color: var(--layout-tertiary-color);
  font-size: 1.3rem;
  font-weight: 600;
}

.subtitle {
  color: var(--layout-secondary-color);
  font-size: 1rem;
}

.title,
.subtitle {
  text-align: center;
}
