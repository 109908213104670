.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action > .name {
  font-weight: bold;
  text-transform: capitalize;
}

.action > .tags {
  text-transform: uppercase;
}
