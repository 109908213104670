.root {
  display: flex;
}

.leftPart {
  width: 18rem;
  background-color: var(--main-background-color);
  min-height: 40rem;
}

.leftPart .descriptionTitle {
  font-weight: bold;
  padding: 0 1rem;
  margin: 2rem 0 0.5rem 0;
  font-size: 1rem;
}

.leftPart .templateTitle {
  color: var(--layout-primary-color);
  padding: 0 1rem;
  margin: 0 0 2rem 0;
  box-sizing: border-box;
  font-size: 1rem;
}

.rightPart {
  width: 100%;
}

.rightPart form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 4rem 0;
  box-sizing: border-box;
}


.description {
  text-align: center;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 0.8rem;
}

/** TEMPLATE NAME PART **/

.titleInputContainer {
  width: 55%;
}

/** CUSTOM LOGO PART **/

.previewContainer {
  width: 17rem;
  justify-self: center;
  align-self: flex-start;
}

.previewContainer h3 {
  font-weight: bold;
}

.uploadInputContainer {
  width: 28rem;
}

.previewContainer .preview {
  background-color: var(--input-background-color);
  border-radius: var(--border-radius-button);
  width: 100%;
  height: 18rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preview canvas {
  width: 8rem !important;
  height: 8rem !important;
}

.previewCatchLine {
  font-size: 0.6rem;
  margin-top: 0.5rem;
  color: gray;
  font-style: italic;
}

@media (max-width:650px) {
  .previewContainer {
    width: 100%;
  }

  .previewContainer:not(:first-child) {
    margin-left: 0;
  }
}

/** COLORS **/

.colorPickerContainer {
  width: 28rem;
}

/** TITLE AND PREVIEW **/

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/** DESTINATION **/

.destinationContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}