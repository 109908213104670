.inputContainer {
  position: relative;
  display: block;
}

.fixedHeight {
  height: 2.5rem;
}

.inputError {
  border: solid 1px red !important;
}

.editInput {
  background-color: transparent;
  margin: 0;
}

td {
  vertical-align: top;
}

.cardRow:hover .actions {
  opacity: 1;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  width: 40px;
  transition: opacity 0.2s ease-in-out;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 5px;
  box-sizing: border-box;
}

.action svg {
  transition: transform 0.2s ease-in-out;
}

.action:hover svg {
  transform: scale(1.5);
}

.cardRow:hover .index {
  background-color: var(--layout-secondary-color);
}

.index {
  text-align: center;
  vertical-align: middle;
  color: white;
  background-color: var(--main-selection-color);
  font-weight: bold;
  height: 100%;
  transition: background-color 0.2s ease-in-out;
}

.selectedIndex {
  background-color: var(--layout-primary-color) !important;
}

.diplayedInTimeline {
  display: flex;
  align-items: center;
  justify-content: center;
}
