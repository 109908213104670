.errorText {
  visibility: visible;
  border-radius: 6px;
  z-index: 1;
  color: red;
  font-size: 0.8rem;
  width: 100%;
}

.rowError {
  margin: 0;
  text-align: right;
  padding: 0 8px;
  user-select: none;
}
