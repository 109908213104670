.cardsBlock {
  grid-area: cards;
  display: flex;
  flex-direction: column;
}

.flag {
  margin-right: 0.5em;
}

.center {
  text-align: center;
}

.indexColumn {
  width: 4%;
  background-color: var(--main-selection-color);
}

.displayedInTimelineColumn {
  width: 8%;
}

.titleColumn {
  width: 32%;
}

.descriptionColumn {
  width: 32%;
}

.positionColumn {
  width: 20%;
}

.buttonsColumn {
  width: 4%;
}

table {
  border-collapse: separate;
  border: 1px solid var(--main-background-color);
  border-radius: 0.5rem;
}

table tr:first-child th:first-child {
  border-top-left-radius: 0.5rem;
}

table tr:first-child th:last-child {
  border-top-right-radius: 0.5rem;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem;
}

.cardsBlock svg {
  width: 1.125rem !important;
}
