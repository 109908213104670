.header {
  word-break: break-word;
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  user-select: none;
  margin: 0.4rem 0;
}

h1 {
  font-size: 1.1rem;
  font-weight: 700;
}

h2 {
  font-size: 1rem;
  font-weight: 600;
}

h3 {
  font-size: 0.9rem;
  font-weight: 500;
}
