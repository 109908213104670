.block {
  background: var(--layout-box-background-color);
  border-radius: var(--border-radius-block);
}

.boxShadow {
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.05);
}

.margin-none {
  margin: 0;
}

.margin-small {
  margin: 0.5rem;
}

.padding-none {
  padding: 0;
}

.padding-small {
  padding: 0.5rem 1rem;
}

.padding-medium {
  padding: 1.5rem;
}

.padding-big {
  padding: 1.5rem;
}

.padding-huge {
  padding: 2rem;
}

.hr {
  margin: 1rem 0 2rem 0;
}

.bottomAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

.breadcrumb {
  margin: 0 0 1rem 0;
}

.blockTitle {
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}
