.nav {
  background-color: var(--white);
  padding-left: 6.45rem;
  width: 11.75rem;
  height: calc(100% - 60px);
  position: absolute;
  top: 60px;
  z-index: 11;
  transition: transform 0.3s ease-out;
  /* 2 x mainbar speed because the width is multiply by 2 */
  transform-origin: center right;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(-100%);
  scrollbar-width: none;
}

.nav::-webkit-scrollbar {
  width: 0;
}

.open {
  transform: translateX(0%);
}

.title {
  color: black;
  text-transform: uppercase;
  text-align: center;
  margin: 2.5rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  user-select: none;
  font-weight: 900;
  letter-spacing: 0.05rem;
}

.block {
  padding: 0 0rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.blockTitle {
  margin: 1.5rem 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  user-select: none;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  transition: opacity 0.2s ease-out;
}

.blockTitle:hover {
  opacity: 0.7;
}

.blockTitle.selected:hover {
  opacity: 1;
}

.blockTitleInner {
  color: #fff;
}

.blockTitleInnerContent {
  font-size: 0.85rem;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  color: var(--skp-color-neutral-700);
}

.blockTitleInner.selected .blockTitleInnerContent, .selected .elemContent {
  color: var(--skp-color-primary-500);
}

.blockTitleInner.selected svg {
  color: var(--skp-color-primary-500);
}

.blockTitleInnerContent.dropdown {
  width: 90%;
}

.blockTitleInnerContent.withIcon {
  width: 85%;
}

.blockTitleInnerContent.dropdown.withIcon {
  width: 75%;
}

.icon svg {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--skp-color-neutral-700);
}

/* BEGINNING OF SELECTED PATCH */

.blockTitleInner.selected {
  color: var(--layout-nav-selected-text-color);
  font-weight: 600;
}

.blockTitle.selected:before {
  content: '';
  position: absolute;
  width: 115%;
  background-color: var(--primary-coral-50);
  top: -0.35rem;
  right: -0.75rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  height: 2.2rem;
  z-index: -1;
}

/* END OF SELECTED PATCH */

.list1 {
  list-style: none;
  padding: 0;
  margin-left: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

.elem {
  color: var(--layout-nav-text-color);
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  margin-left: 1rem;
  font-size: 0.8rem;
  line-height: 1.2rem;
  user-select: none;
  font-weight: 300;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  transition: opacity 0.2s ease-out;
}

.elem:hover {
  opacity: 0.7;
}

.elem.selected:hover {
  opacity: 1;
}

/* BEGINNING OF SELECTED PATCH */

.elem.selected {
  font-weight: 600;
}

.elem.selected:before {
  content: '';
  position: absolute;
  width: 108%;
  background-color: var(--primary-coral-50);
  top: -0.3rem;
  right: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  height: 2rem;
  z-index: -1;
  transition: opacity 0.2s ease-out;
}

/* END OF SELECTED PATCH */

.elemContent {
  vertical-align: middle;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
  color: var(--skp-color-neutral-700);
}

.elemContent.dropdown {
  width: 68%;
}

.list2 {
  list-style: none;
  padding: 0;
}

.subElem {
  color: var(--layout-primary-color);
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  margin-left: 2.4rem;
  font-size: 0.75rem;
  line-height: 0.95rem;
  user-select: none;
  font-weight: 300;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  transition: opacity 0.2s ease-out;
}

.subElem:last-child {
  margin-bottom: 0.5rem;
}

.subElem:hover {
  opacity: 0.7;
}

.subElem.selected:hover {
  opacity: 1;
}

/* BEGINNING OF SELECTED PATCH */

.subElem.selected {
  font-weight: 600;
}

.subElem.selected:before {
  content: '';
  position: absolute;
  width: 111%;
  background-color: var(--primary-coral-50);
  top: -0.4rem;
  right: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  height: 1.8rem;
  z-index: -1;
}

.subElemContent {
  vertical-align: middle;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  color: var(--skp-color-neutral-700);
}

.selected .subElemContent, .selected svg {
  color: var(--skp-color-primary-500);
}

/* END OF SELECTED PATCH */

.icon {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  margin-right: 0.55rem;
  position: relative;
  vertical-align: middle;
}

.dropdownTiny {
  margin-top: -1.3rem;
}

.dropdownIcon,
.dropdownIconSubMenu {
  position: absolute;
  right: 0;
  display: inline-block;
}

.dropdownIconSubMenu {
  right: 0.8rem;
}

.dropdownIcon svg,
.dropdownIconSubMenu svg {
  transition: transform 0.3s ease-in-out;
  font-size: 14px;
}

.dropdownIcon.open svg,
.dropdownIconSubMenu.open svg {
  transform: rotate(180deg);
}
