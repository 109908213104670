.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--skp-color-neutral-50);
  width: 17.95rem;
  height: calc(100vh - 3.8rem);
  position: absolute;
  top: 3.8rem;
  z-index: 11;
  transition: all 0.3s ease-out;
  /* 2 x mainbar speed because the width is multiply by 2 */
  transform-origin: center right;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(-100%);
  scrollbar-width: none;
  color: var(--skp-color-neutral-700);
}

.boxShadow {
  box-shadow: rgb(0 0 0 / 26%) 9px 1px 15px -6px;
}

.nav::-webkit-scrollbar {
  width: 0;
}

.tabContainer {
  margin-top: 1.5rem;
  padding-left: 40px;
}

.open {
  transform: translateX(0%);
}

.blockTitle {
  display: flex;
  align-items: center;
  color: var(--skp-color-neutral-700);
  font-weight: bold;
  padding: 1.1rem 0 1.1rem 1rem;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0.7rem;
}

.selected .blockTitle {
  color: var(--skp-color-primary-500);
}

.selected svg, .selected .itemName {
  color: var(--skp-color-primary-500);
}

.blockTitle:hover:not(.selected) {
  opacity: 0.7;
  border-top-left-radius: 6rem;
  border-bottom-left-radius: 6rem;
}

.blockTitle p {
  margin: 0;
}

.blockTitle svg {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.blockTitleAndDropdownOpen {
  margin-bottom: 0;
}

.selected:not(.itemSub),
.selected:not(.itemSub):visited,
.selected:not(.itemSub):hover,
.selected:not(.itemSub):active {
  background-color: var(--skp-color-primary-50);
  color: var(--skp-color-primary-500);
  border-top-left-radius: 6rem;
  border-bottom-left-radius: 6rem;
}

/** START OF THE CHILD DROPDOWN **/

.dropdownList {
  list-style: none;
  padding: 0;
  margin-left: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

.name {
  flex: 1 1 0;
  margin-right: 0.5rem;
}

/** CSS FOR DROPDOWN ICON **/

.dropdownIcon {
  display: inline-block;
}

.dropdownIcon svg {
  transition: transform 0.3s ease-in-out;
  font-size: 14px;
}

.dropdownIcon.open svg {
  transform: rotate(180deg);
}
/* BEGINNING OF SELECTED ELEMENT */

.itemActionContainer {
  display: flex;
  margin: 0 0 0 2rem;
  padding: 1rem 0;
}

.itemActionContainer.itemSub {
  padding: 0.5rem 0;
}

.itemActionContainer:not(.itemSub).selected {
  margin: 0 0 0 1rem;
  padding: 1rem 0 1rem 1rem;
}

.itemActionContainer.selected .itemName {
  font-weight: bold;
}

.itemContainer {
  margin: 0 0 0 3rem;
  padding: 1rem 0;
}

.itemActionContainer:hover,
.itemContainer:hover {
  opacity: 0.7;
}

.itemActionContainer.selected:hover,
.itemContainer.selected:hover {
  opacity: 1;
}

.itemActionContainer .dropdownIcon {
  padding-right: 1rem;
}

.itemName {
  color: var(--skp-color-neutral-700);
  cursor: pointer;
  flex: 1 1 0;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  position: relative;
  transition: opacity 0.2s ease-out;
  user-select: none;
  vertical-align: middle;
}
