.container {
  height: 40px;
  position: relative;
  background: #191919;
}

.canvas {
  width: 100%;
  position: absolute;
  bottom: 0;
  user-select: none;
  cursor: col-resize;
}
