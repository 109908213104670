.container {
  background: black;
  position: relative;
  overflow: hidden;
}

.containerVertical {
  background-color: rgb(32, 32, 32);
  overflow: hidden;
  position: relative;
}

.player {
  position: absolute;
  width: 100%;
  height: 100%;
}

.spinnerContainer {
  position: relative;
  top: 90%;
}
