.root {
  font-weight: bold;
  position: relative;
  color: var(--color-info);
  cursor: pointer;
}

.selected {
  color: var(--layout-primary-color);
  position: relative;
}

.selected::after {
  display: block;
  content: '';
  width: 100%;
  height: 3px;
  background: var(--layout-primary-color);
  position: absolute;
  bottom: -0.3rem;
  left: 0;
}
