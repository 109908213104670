.noEditingBrief {
  text-align: center;
}

.seeSample {
  color: var(--layout-primary-color) !important;
}

.icon {
  margin-right: 1rem;
}

.brief > * {
  margin-bottom: 0.5rem;
}
