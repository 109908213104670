.contentContainer {
  display: flex;
  background-color: white;
  width: 100%;
  height: 100vh;
}
.leftColumn {
  padding: 2.4rem;
  box-sizing: border-box;
  overflow: auto;
  width: 100%;
}
.leftColumn h1 {
  color: var(--color-grey);
  margin: 0 0 3rem 0;
}
.skeepersLogo {
  height: auto;
  margin-bottom: 3rem;
}
.leftSubColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 0;
}
.text {
  margin: 0 0 2.5rem 0;
  color: var(--color-grey);
  line-height: 2.3rem;
  letter-spacing: 0.45px;
}
.buttonContainer {
  width: fit-content;
}
.contentContainer a div {
  width: fit-content;
}

@media screen and (min-width: 500px) and (max-width: 1050px) {
  .contentContainer {
    width: 100vw;
  }
  .leftColumn {
    padding: 4rem;
    width: 100%;
  }
  .leftSubColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leftColumn h1 {
    font-size: 2.8rem;
    font-weight: bold;
    max-width: 35rem;
  }
  .text {
    max-width: 37rem;
  }
  .buttonContainer {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1051px) {
  .skeepersLogo {
    width: 8rem;
    margin-bottom: 0rem;
  }
  .illustrationContainer {
    display: flex;
  }
  .text {
    max-width: 37rem;
  }
  .contentContainer {
    z-index: 4;
    background-image: url(./assets/groupBackground.svg);
    background-position: bottom -531px left 165px;
    background-repeat: no-repeat;
  }
  .leftColumn {
    padding: 2.5rem 0 0 2.5rem;
  }
  .leftSubColumn {
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
    margin: 2rem 0 0 2rem;
  }
  .leftColumn h1 {
    font-size: 3.5rem;
    font-weight: bold;
    width: 37rem;
  }
  .buttonContainer {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1500px) {
  .leftColumn {
    padding: 4.5rem 0 0 4.5rem;
  }
  .leftSubColumn {
    margin: 5rem 0 0 5rem;
  }
}

@media screen and (max-height: 660px) and (min-width: 1051px) {
  .contentContainer {
    background-position: bottom -650px left 165px;
  }
}
