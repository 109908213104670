.tableOverflow {
  overflow-x: auto;
}

.header,
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  margin: 0 1.5rem;
}

.dropdownCell {
  display: flex;
  flex: 0.2;
  justify-content: flex-end;
  cursor: pointer;
}

.rowElement,
.headerElement {
  display: flex;
  flex: 1;
}

.rowElement {
  min-width: 0;
  overflow: hidden;
  align-self: stretch;
}

.rowElement:first-child {
  border: none;
}

.leftBorder {
  border-left: 1px solid var(--main-background-color);
}

.headerElement {
  display: flex;
  padding: 1rem 0;
  color: #707070;
  align-items: center;
  justify-content: center;
  user-select: none;
  min-width: 0;
}

.headerSortable {
  cursor: pointer;
}

.sortingIcon {
  opacity: 0;
  margin-left: 0.25rem;
}

.sortingIcon:hover {
  cursor: pointer;
}

.headerElement:hover .sortingIcon {
  opacity: 1;
}

.sortingSelection {
  opacity: 1;
}

.dropdownIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownContainer {
  flex: 0.1;
}
