.list {
  margin: 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  list-style: none;
  background-color: var(--main-background-color);
}

.list > .item {
  display: flex;
  align-items: center;
}

.list > .item.success {
  color: var(--color-success);
}

.list > .item.warning {
  color: var(--color-warning);
}

.list > .item.ignored {
  color: var(--video-text-color-secondary);
}

.list > .item > svg {
  margin-right: 0.5em;
}

.list > .item > .key {
  margin-right: 0.5em;
  font-weight: 700;
}

.list > .item > .value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
