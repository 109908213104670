.canvasContainer {
  display: flex;
  justify-content: center;
  height: 287.5px;
  width: 518px;
  background-color: rgb(242, 242, 242);
  margin-bottom: 2rem;
}

.canvas {
  background: repeating-conic-gradient(#d3d3d3 0% 25%, transparent 0% 50%) 50% / 20px 20px;
  border: solid 1px black;
  height: 100%;
}
