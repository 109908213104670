.nav {
  background-color: var(--neutral-50);
  width: 6.2rem;
  height: 100%;
  height: calc(100% - 60px - 1.4rem); /* We remove the topbar height and the padding */
  position: absolute;
  border-top-right-radius: 10px;
  z-index: 12;
  transition: transform 0.3s ease-out;
  transform-origin: center right;
  text-align: center;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(-100%);
  scrollbar-width: none;
}

.selected svg {
  color: var(--skp-color-white);
}

.nav::-webkit-scrollbar {
  width: 0;
}

.open {
  transform: translateX(0);
}

.main {
  margin: 0;
  padding: 0;
  list-style: none;
}

.elem {
  position: relative;
  transform: scale(1);
  transition: transform 0.08s ease-in-out;
}

.elem:hover {
  transform: scale(1.1);
}

.selected:hover {
  transform: scale(1);
}

.selected:before {
  content: '';
  position: absolute;
  width: 90%;
  background-color: var(--white);
  top: 2px;
  right: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  height: 4.8rem;
}

.nav ul li:last-of-type div div {
  font-size: 27px;
  height: fit-content;
}
