.root {
  display: inline-flex;
  background-color: var(--input-background-color);
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
}

.option {
  padding: 0 0.3rem;
  margin: 0.3rem;
  color: rgb(134, 134, 134);
  transition: all 0.3s ease-in-out;
  user-select: none;
}

.option:not(.disabled):not(.readOnly):hover {
  cursor: pointer;
}
.selected:not(.readOnly) {
  font-weight: 600;
  color: var(--layout-primary-color);
}

.selected {
  font-weight: 600;
}

.disabled {
  text-decoration: line-through;
}

.error {
  border: solid 1px var(--color-error);
}
