.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  min-width: 3rem;
  width: 3rem;
  height: 1.5rem;
  margin: 0.5rem 0;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: rgb(227, 226, 226);
  z-index: 1;
}

.knobs {
  content: '';
  position: absolute;
  top: 50%;
  left: 9%;
  width: 6px;
  height: 6px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 6px 6px;
  background-color: #ffff;
  border-radius: 50%;
  transform: translateY(-50%);
}

.checkbox:checked + .knobs {
  content: '';
  left: 54%;
}

.checkbox:checked ~ .layer {
  background-color: var(--layout-primary-color);
}

.checkbox:read-only {
  cursor: unset;
}

.knobs,
.knobs,
.layer {
  transition: 0.1s ease all;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
