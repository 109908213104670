.container {
  display: flex;
  align-items: center;
}

.progress {
  position: relative;
  flex: 1;
  margin: 0 1rem;
  height: 0.5rem;
  cursor: pointer;
  background-color: var(--main-background-color);
  border-radius: 0.5rem;
}

.progress > .meter {
  height: 100%;
  background-color: var(--layout-secondary-color);
  border-radius: 0.5rem;
  position: relative;
}

.progress > .knob {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--layout-primary-color);
}

.progress > .limit {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 0.4rem;
  height: 1.5rem;
  border-top: 0.3rem solid var(--layout-primary-color);
  border-bottom: 0.3rem solid var(--layout-primary-color);
}

.progress > .limitEditable {
  cursor: col-resize;
}

.progress > .leftLimit {
  border-left: 0.3rem solid var(--layout-primary-color);
  transform: translate(-0.15rem, -50%);
}

.progress > .rightLimit {
  border-right: 0.3rem solid var(--layout-primary-color);
  transform: translate(calc(-100% + 0.15rem), -50%);
}
