.warning,
.warning:visited {
  background-color: rgb(255, 145, 0);
  color: #fff;
  text-align: center;
  user-select: none;
  line-height: 2rem;
  height: 2rem;
  font-size: 1rem;
  width: 100%;
  position: relative;
  display: block;
  transition: background-color 0.2s ease-in-out;
}

.warning:hover {
  background-color: rgb(252, 155, 27);
  color: #fff;
}
