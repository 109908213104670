.tag {
  color: #000;
  font-size: 0.6rem;
  user-select: none;
  border-radius: 0.2rem;
  background: var(--color-info);
  display: inline-block;
  margin: 0.2rem;
  padding: 0.3rem 0.6rem;
  font-weight: 400;
}

.action {
  cursor: pointer;
}

.action:hover {
  transform: translateY(1px);
  box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.05);
  filter: brightness(1.05);
}
