.grid {
  --grid-min-rows: 3;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns, 1), minmax(0, 1fr));
  grid-template-rows: repeat(var(--grid-min-rows), 1fr);
  gap: 0.5rem 1rem;
  align-items: stretch;
}

.column3 {
  --grid-column: 3;
}

.column2 {
  --grid-column: 2;
}

.gridItem {
  grid-column: var(--grid-item-column, 1);
}

@media (min-width: 600px) and (max-width: 960px) {
  .grid {
    grid-template-columns: repeat(var(--grid-columns-md, 1), minmax(0, 1fr));
  }
  .gridItem {
    grid-column: var(--grid-item-column-md, 1);
  }
}

@media (min-width: 960px) {
  .grid {
    grid-template-columns: repeat(var(--grid-columns-lg, 1), minmax(0, 1fr));
  }
  .gridItem {
    grid-column: var(--grid-item-column-lg, 1);
  }
}
