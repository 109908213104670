.root {
  overflow-y: scroll;
  justify-content: center;
  line-height: 0;
  flex-flow: row wrap;
  font-size: 0;
  align-content: flex-start;
  background: rgb(32, 32, 32);
}

.root::-webkit-scrollbar,
.root::-webkit-scrollbar {
  width: 15px;
}

.root::-webkit-scrollbar-thumb,
.root::-webkit-scrollbar-thumb {
  min-height: 50px;
  background-color: hsl(0, 0%, 40%);
  border: 3px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
  cursor: pointer;
}

.root::-webkit-scrollbar-track,
.root::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.elem {
  width: 200px;
  height: 112px;
  margin: 10px;
  display: inline-block;
  background-color: black;
  background-size: cover;
  background-position: center center;
  position: relative;
  cursor: pointer;
  border: solid 1px #3f3f3f;
  transition: border 0.5s ease-in;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.elem.empty {
  height: 0;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.elem:hover {
  border: solid 1px #808080;
}

.text {
  color: white;
  background: rgba(0, 0, 0, 0.8);
  height: 26px;
  line-height: 26px;
  font-size: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-family: var(--main-font);
  border: none;
  box-sizing: border-box;
  border-radius: 0;
  outline: none;
}

.info::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 2rem solid transparent;
}

.used::before {
  border-top: 2rem solid #41e687;
}

.usedIcon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem
}

.refused::before {
  border-top: 2rem solid var(--skp-color-error-500);
}

.refusedIcon {
  position: absolute;
  top: 0.25rem;
  right: 0.2rem
}

.duration {
  position: absolute;
  color: white;
  background: rgba(0, 0, 0, 0.8);
  height: 1rem;
  top: 5px;
  left: 5px;
  line-height: 1rem;
  font-size: 13px;
  padding: 0.2rem;
  border-radius: var(--border-radius-button);
}

.select {
  margin-top: 10px;
  margin-left: 10px;
}
