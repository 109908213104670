.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
}

.button {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  border-radius: 50%;
}

.button.big {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  margin: 0 0.5rem;
}
