.container {
  height: 250px;
}

.icon {
  display: block;
  height: 2rem !important;
  width: 2rem !important;
}

.title {
  font-weight: bold;
  font-size: 1.4rem;
}

.textContainer {
  text-align: center;
}
