.root {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message {
  margin-bottom: 4rem;
  font-size: 1rem;
}

.svg {
  height: 12rem;
}
