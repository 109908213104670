.headerLeft {
  display: inline-flex;
  align-items: center;
  background-color: var(--layout-primary-color);
  width: 17.95rem;
  height: 3.8rem;
}

.burger {
  height: 3.8rem;
  position: relative;
  width: 6.2rem;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.burger.hide {
  opacity: 0;
  cursor: default;
}

.burger svg {
  height: 100%;
  position: absolute;
  top: 0;
  width: 35px;
  fill: #000;
}

.container {
  height: 12px;
  width: 12px;
  position: relative;
  z-index: 14;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--layout-primary-color);
}

.container hr {
  width: 100%;
  height: 2px;
  background-color: var(--layout-primary-color);
  border: none;
  border-radius: 2px;
  margin: 0;
  transition: all 0.2s ease-in-out;
}

.hrRotateOne {
  transform: rotate(-45deg) translateY(2px);
}

.hrOpacity {
  opacity: 0;
}

.hrRotateTwo {
  transform: rotate(45deg) translateY(-2px);
}

.icon {
  display: block;
  position: relative;
  z-index: 12;
  margin-left: 1.5rem;
}

.logo {
  user-select: none;
  height: auto;
  width: 7.5rem;
}

.logoChristmas {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
}

@media screen and (max-width: 600px) {
  .burger.hide {
    display: none;
  }
}
