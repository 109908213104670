.dropdown {
  /*transition: max-height .5s ease-in-out;*/
  overflow: hidden;
  /*max-height: 0;*/
  /*height: auto;*/
  /*  transform-origin: 50% 0;
     transition:transform 1s ease;*/
}

.dropdown.open {
  /*max-height: 100vh;*/
  /*  transform: scaleY(1);*/
}

.dropdown.close {
  /*  height: 0;*/

  max-height: 0;

  /* transform: scaleY(0);*/
}
