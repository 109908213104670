.openButtonContainer {
  position: relative;
}

.openButton {
  position: absolute;
  padding: 0.5rem;
  top: 10%;
  right: 0;
  border-bottom-left-radius: var(--border-radius-button);
  background-color: var(--layout-primary-color);
  cursor: pointer;
}

.nav {
  background-color: #e0e0e0;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  transition: all 0.3s ease-out;
  /* 2 x mainbar speed because the width is multiply by 2 */
  transform-origin: center right;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(100%);
  scrollbar-width: none;
  width: 17.95rem;
}

.arrowContainer {
  padding: 1rem;
  height: 2rem;
}

.arrow {
  /*dirty fix*/
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.nav::-webkit-scrollbar {
  width: 0;
}

.open {
  transform: translateX(0%);
}

.blockTitle {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  padding: 1.1rem 0 1.1rem 1rem;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0.7rem;
}

.blockTitle:hover:not(.selected) {
  opacity: 0.7;
  border-top-left-radius: 6rem;
  border-bottom-left-radius: 6rem;
}

.blockTitle p {
  margin: 0;
}

.blockTitle svg {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.selected,
.selected:visited,
.selected:hover,
.selected:active {
  background-color: var(--main-background-color);
  color: var(--layout-tertiary-color);
  border-top-left-radius: 6rem;
  border-bottom-left-radius: 6rem;
}
