.root :global(.DayPickerInput-Overlay) {
  border-radius: var(--border-radius-button);
  top: 0.5rem;
  overflow: hidden;
  z-index: 3;
}

.root :global(.DayPicker-wrapper) {
  padding-bottom: 0 !important;
}

.root :global(.DayPicker-Month) {
  margin: 0;
}

.root :global(.DayPicker-WeekdaysRow) {
  text-transform: uppercase;
  background-color: #f7f7ff;
  color: #a3a6b4;
  font-weight: 600;
}

.root :global(.DayPickerInput) input {
  padding: 0.5rem;
  font-size: 0.9rem;
  outline: none;
}

.root :global(.DayPicker-Day--selected):not(:global(.DayPicker-Day--outside)):not(:global(.DayPicker-Day--start)):not(:global(.DayPicker-Day--end)) {
  background-color: var(--layout-secondary-color) !important;
}

.root :global(.DayPicker-Day--start),
.root :global(.DayPicker-Day--end) {
  background-color: var(--layout-primary-color) !important;
}

.root :global(.DayPicker-Caption) {
  text-align: center;
  margin: 1rem;
}

.root :global(.DayPicker-NavButton--prev) {
  right: unset !important;
  left: 1rem !important;
}

.root :global(.DayPicker-NavButton--next) {
  left: unset !important;
  right: 1rem !important;
}

.root :global(.DayPicker-Day--today) {
  color: var(--layout-primary-color);
}

.calendarIcon {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
}

.dateRangePicker {
  display: inline-flex;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 11%);
  border-radius: var(--border-radius-button);
  align-items: center;
  padding: 0 2rem 0 0.3rem;
  margin: 0.5rem 0;
  position: relative;
}

.dateRangePicker :global(.DayPickerInput) input {
  border: none;
  width: 6rem;
  text-align: center;
  height: var(--height-input);
  box-sizing: border-box;
}

.dateRangePicker :global(.DayPicker-Day) {
  border-radius: 0 !important;
  margin: 0 0.2rem !important;
}

.dateRangePicker :global(.DayPicker-Day--start) {
  border-top-left-radius: var(--border-radius-button) !important;
  border-bottom-left-radius: var(--border-radius-button) !important;
}

.dateRangePicker :global(.DayPicker-Day--end) {
  border-top-right-radius: var(--border-radius-button) !important;
  border-bottom-right-radius: var(--border-radius-button) !important;
}

.to {
  color: #707070;
  font-size: 0.9rem;
}
.datePicker {
  display: flex;
  background-color: white;
  border-radius: var(--border-radius-button);
  align-items: center;
  padding-right: 1rem;
  margin: 0.5rem 0;
  position: relative;
}

.datePicker :global(.DayPickerInput) {
  width: 100%;
  height: var(--height-input);
  display: flex;
  flex-direction: column;
}

.datePicker :global(.DayPickerInput) input {
  min-width: 5rem;
  width: 100%;
  background: rgb(245, 245, 245);
  font-family: var(--main-font);
  color: black;
  border: solid 1px transparent;
  border-radius: var(--border-radius-button);
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.datePicker :global(.DayPickerInput) input::placeholder {
  font-style: italic;
  color: rgb(134, 134, 134);
}

.datePicker :global(.DayPickerInput) input:focus:not([readonly]) {
  border: solid 1px var(--layout-primary-color);
  background-color: var(--input-focused-background-color);
}

.datePicker :global(.DayPicker-Day) {
  border-radius: var(--border-radius-button) !important;
  margin: 0 0.2rem !important;
}

.datePicker.error :global(.DayPickerInput) input {
  border: solid 1px var(--color-error);
}
