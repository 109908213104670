.block {
  background-color: rgb(112, 112, 255);
  border-radius: 3px;
  overflow: hidden;
  position: absolute;
  transition: background-color 0.4s ease-in-out;
  cursor: grab;
  font-family: var(--main-font);
}

.video {
  background-color: rgb(112, 112, 255);
}
.block.audio {
  background-color: rgb(255, 112, 112);
}
.block.image {
  background-color: #41e687;
}

.selected {
  box-shadow: 0px 0px 0px 2px #ffffff inset;
}

.block.ismoving,
.block.isresizing {
  z-index: 999;
}

.block.video.ismoving,
.block.video.isresizing {
  background-color: rgba(112, 112, 255, 0.5);
}

.block.audio.ismoving,
.block.audio.isresizing {
  background-color: rgba(255, 112, 112, 0.5);
}

.blockResizeLeft {
  position: absolute;
  width: 10px;
  height: 100%;
  cursor: w-resize;
  left: -2px;
}

.blockName {
  margin: 0px 10px;
  color: #fefefe;
  white-space: nowrap;
  position: absolute;
  width: 100%;
}

.blockResizeRight {
  position: absolute;
  right: -2px;
  top: 0px;
  width: 10px;
  height: 100%;
  cursor: e-resize;
}

.warning {
  background: rgba(255, 0, 0, 0.6);
  width: 6px;
}

.line {
  width: 1px;
  height: 100%;
  position: absolute;
  z-index: 2;
}
